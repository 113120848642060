import 'regenerator-runtime/runtime';
import {SedestralLogic} from "./sedestral-interface-modules/sedestral-interface-component/SedestralLogic";
import {Network} from "./network/Network";
import {Panel} from "./products/panel/Panel";
import {PanelNetwork} from "./products/panel/network/PanelNetwork";
import {config} from "./config";
import {ProductName} from "./models/product/ProductName";
import {ProductType} from "./models/product/ProductType";
import Hotjar from '@hotjar/browser';
import {EntityService} from "./services/entity/EntityService";
import {PermissionsService} from "./services/permissions/PermissionsService";
import {Resources} from "./resources/Resources";

config.import(ProductName.toString(ProductType.PANEL));
SedestralLogic.ready(async () => {
    await SedestralLogic.init();
    await PanelNetwork.init();
    await Network.init(ProductName.toString(ProductType.PANEL));
    await Panel.init();

    try {
        if (!config.isDev && (!EntityService.activeSite || !PermissionsService.canProduct(ProductType.INBOX))) {
            const hotjarPanelId = config.hotjarPanelId;
            const hotjarVersion = 6;
            Hotjar.init(Number(hotjarPanelId), hotjarVersion);
        }
    } catch (e) {
        console.error(e);
    }

    try {
        if (Network.logged && !config.isDev) {
            let params: any = {
                language: Resources.language,
                identifier: EntityService.activeEntity.id,
                name: EntityService.activeEntity.name,
                email: EntityService.activeEntity.email,
                avatarUrl: EntityService.activeEntity.avatar?.url
            };

            window["SEDESTRAL_ID"] = config.livechatPanelId;
            window["SEDESTRAL_PARAMS"] = params;

            try {
                (function () {
                    let v = "v", d = document, s;
                    try {
                        v = localStorage.getItem("sdt-v");
                    } catch (e) {
                    }
                    s = d.createElement("script");
                    s.async = true;
                    s.src = config.livechatJsUrl() + v;
                    d.head.appendChild(s);
                })();
            } catch (e) {
                console.error(e);
            }
        }
    } catch (e) {
        console.error(e);
    }
})
;