export enum OfferProductSolutionType {
    ACCOUNT = 0,
    LIVECHAT = 1,
    MAILS = 2,
    IA = 3,
    SITE_CHANNEL = 4,
    COMMUNITY = 5,
    ARTICLES = 6,
    AUTOPILOT = 7,
    WRITING_STYLE = 8,
    SEO_RANKING = 9
}